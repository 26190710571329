<!-- 行程详情 -->
<template>
  <div class='container'>
    <van-button v-if="isPay" @click="goToPay" style="margin: 0.1rem 0;width: 100%;position: sticky;top: 0;z-index: 99;"
      type="primary">前往支付</van-button>
    <van-loading v-if="loading" style="position: absolute;left: 50%;transform: translateX(-50%);top: 50%;" size="42"
      type="spinner" color="#D6562E" />
    <div class="titleImg"
      @click="handlerSeeImg([datas.imageUrl ? datas.imageUrl : tuiteFirstImg ? tuiteFirstImg : qyNationalImageList[0] ? qyNationalImageList[0] : require('@/assets/png/defalt.jpg')])">
      <img 
        :src="datas.imageUrl ? datas.imageUrl : tuiteFirstImg ? tuiteFirstImg : qyNationalImageList[0] ? qyNationalImageList[0] : require('@/assets/png/defalt.jpg')"
        alt="">
    </div>
    <div class="oneBox">
      <div v-if="_h5groupDetailsStrategy().F" style="padding: 0 0.8rem;">
        <div style="padding: 0 0.1rem;">
          <div class="p1" v-if="datas.planName">
            <TextRoll :text="strSub(datas.planName)[0]"></TextRoll>
          </div>
          <p v-if="JSON.stringify(datas) !== '{}' && datas.planName" class="p3">{{ strSub(datas.planName)[1] }}</p>
          <p class="p2">{{ datas.budgetName }}</p>
        </div>
        <div style="display: flex;align-items: center;flex-direction: column;justify-content: center;">
          <div class="line"></div>
          <div class="prices" v-if="datas.budgetDetailResult">
            <p v-if="_obtainPriceDisplayStatus().ALL || _obtainPriceDisplayStatus().SINGLE_PERSON" class="priceP1"
              style="margin-right: 0.4rem;"><span>¥</span><span style="display: inline-block;margin:0 0.05rem;"
                :style="{ fontSize: fontSize(parseInt(datas.budgetDetailResult.saleTotalPrice / datas.useNumber), 3.5, 0.6) + 'rem' }">
                {{ (datas.budgetDetailResult.saleTotalPrice / datas.useNumber).toFixed(2) }}</span><span>/人</span>
            </p>
            <p v-if="_obtainPriceDisplayStatus().ALL || _obtainPriceDisplayStatus().GROUP_PRICE" class="priceP1">
              <span>¥</span><span style="display: inline-block;margin:0 0.05rem;"
                :style="{ fontSize: fontSize(parseInt(datas.budgetDetailResult.saleTotalPrice / datas.useNumber), 3.5, 0.6) + 'rem' }">
                {{ datas.budgetDetailResult.saleTotalPrice ? datas.budgetDetailResult.saleTotalPrice : 0 }}
              </span><span>/团</span>
            </p>
          </div>
        </div>
        <div class="planeDetail">
          <div class="planeDetailL">
            <div class="yuan">
              <div class="fx">
                f
              </div>
            </div>
          </div>
          <div class="planeDetailR">
            <p class="planeDetailRp1">{{ datas.departureDate | dateFormat1 }} - {{
              (datas.departureDate +
                (datas.planDay * 86400000)) | dateFormat1
            }}</p>
            <p class="planeDetailRp2">{{ datas.useNumber }} 名贵宾</p>
            <p class="planeDetailRp3">{{ datas.planDay }}天 <span style="margin: 0 0.1rem;">/</span> <span
                v-if="datas.planName">{{
                  datas.countrySize
                }}个国家</span> <span style="margin: 0 0.1rem;">/</span> {{
                  datas.spotSize ? datas.spotSize : 0
                }}个景点</p>
          </div>
        </div>
        <div v-if="datas.budgetDetailResult"
          :style="{ marginTop: `${(0.47 / 0.67) * fontSize(parseInt(datas.budgetDetailResult.saleTotalPrice / datas.useNumber), 3.5, 0.67) + 'rem'}` }"
          class="line" style="margin-bottom: 0.6rem;"></div>
        <div v-else class="line" style="margin-bottom: 0.6rem;margin-top: 0.47rem;"></div>
      </div>
      <div class="btnBox">
        <div
          v-if="_obtainPriceDisplayStatus().PERSONAL_INFORMATION || _obtainPriceDisplayStatus().COMPANY_INFORMATION || _h5groupDetailsStrategy().ITINERARY_MAP"
          class="btn" @click="jumpPoint('方案概括')">
          <img style="height: 0.55rem;margin-right: 0.1rem;
                        " :src="require('@/assets/png/plane/WechatIMG301.jpeg')" alt="">
          <div class="text">
            方案概括
          </div>
          <div class="line1"
            v-if="_h5groupDetailsStrategy().ITINERARY_MAP || _isDetailPrice().DETAIL_PRICE || programmeId > 0"></div>
        </div>
        <div v-if="_h5groupDetailsStrategy().ITINERARY_MAP" class="btn" @click="jumpPoint('行程地图')">
          <img style="height: 0.55rem;margin-right: 0.1rem;
                        " :src="require('@/assets/png/plane/WechatIMG302.jpeg')" alt="">
          <div class="text">
            行程地图
          </div>
          <div v-if="_isDetailPrice().DETAIL_PRICE || programmeId > 0" class="line1"></div>
        </div>
        <div v-if="_isDetailPrice().DETAIL_PRICE" class="btn" @click="jumpPoint('详细报价')">
          <img style="height: 0.55rem;margin-right: 0.1rem;
                        " :src="require('@/assets/png/plane/WechatIMG303.jpeg')" alt="">
          <div class="text">
            详细报价
          </div>
          <div class="line1" v-if="programmeId > 0"></div>
        </div>
        <div class="btn" v-if="programmeId > 0" @click="jumpPoint('价格说明')">
          <img style="height: 0.55rem;margin-right: 0.1rem;
                        " :src="require('@/assets/png/plane/WechatIMG304.jpeg')" alt="">
          <div class="text">
            价格说明
          </div>
        </div>
      </div>
    </div>
    <div v-if="_h5groupDetailsStrategy().F" class="line3Box" style="margin-bottom:0.8rem;margin-top: 0.6rem;">
      <div class="line3">
        <div class="yuan1" style="left: -0.06rem;"></div>
        <div class="yuan1" style="right: -0.06rem"></div>
      </div>
    </div>
    <div class="twoBox" v-if="_obtainPriceDisplayStatus().COMPANY_INFORMATION">
      <img style="width:1rem;height: 1rem;" :src="userInfo.logs" alt="">
      <p class="p2">{{ userInfo.mechanismName }}</p>
      <p class="p3">{{ userInfo.slogan }}</p>
      <p class="p4">{{ userInfo.adressRegistration }}</p>
      <p class="p5">设立 <span>{{ companyEstablishmentYear(userInfo.startTime) }}</span>年
        覆盖<span>{{ userInfo.countrySize }}</span>国 跨越<span>{{ userInfo.citySize }}</span>城</p>
      <p class="p6">10粉丝 / 5关注 / 28获赞</p>
      <p class="p7">世界旅游城市联合会终身会员</p>
      <div class="infoBoxB">
        <img v-for="(item) in userInfo.uFileList" :key="item.id" class="infoBoxBImgs" :src="item.fileUrl" alt="">
      </div>
    </div>
    <div class="threeBox" v-if="_obtainPriceDisplayStatus().PERSONAL_INFORMATION">
      <div class="infoBox">
        <div class="infoBoxT">
          <div class="infoBoxTInfo">
            <div class="infoBoxTInfoL">
              <img class="infoBoxTimgs" :src="userInfo.userInfoResult.imageUrl">
            </div>
            <div class="infoBoxTInfoR">
              <p class="p1">{{ userInfo.userInfoResult.userName }}</p>
              <div class="infoBoxTRTItle" v-if="userInfo.userInfoResult.career">
                <div class="zhedian" style="left: -0.3rem;"></div>
                {{ userInfo.userInfoResult.career }}
                <div class="zhedian" style="right: -0.3rem;"></div>
              </div>
              <div class="relationLineBox">
                <img class="svg1" :src="require('@/assets/png/plane/WechatIMG322.png')">
                <!-- <p class="relationLineBoxP1">{{ userInfo.userInfoResult.loginAccount }}</p> -->
                <a class="relationLineBoxP1" :href="'tel:' + userInfo.userInfoResult.loginAccount">{{
                  userInfo.userInfoResult.loginAccount }}</a>
              </div>
            </div>
          </div>
          <div class="datumBox">
            <div style="display: flex;align-items: center;" v-for="(item, index) in datumList" :key="index">
              <div class="datumBoxLineBox">
                <p class="datumBoxLineP1">{{ calculationPeriod(userInfo.userInfoResult, item) }}</p>
                <p class="datumBoxLineP2">{{ item.name }}</p>
              </div>
              <div v-if="index != datumList.length - 1" class="datumBoxLine"></div>
            </div>
          </div>
        </div>
        <div class="infoBoxC">
          {{ userInfo.userInfoResult.customerMessage }}
        </div>
        <div class="infoBoxB">
          <img v-for="item in userInfo.userInfoResult.uFileList" :key="item.id" class="infoBoxBImgs" :src="item.fileUrl"
            alt="">
        </div>
      </div>
    </div>
    <div class="line3Box" v-if="_obtainPriceDisplayStatus().PERSONAL_INFORMATION">
      <div class="line3" style="margin: 0.5rem 0;">
        <div class="yuan1" style="left: -0.06rem;"></div>
        <div class="yuan1" style="right: -0.06rem"></div>
      </div>
    </div>
    <div class="fourBox" v-if="_h5groupDetailsStrategy().ITINERARY_MAP && datas.planMap">
      <p class="p1" style="margin-bottom: 0.1rem;">行程地图</p>
      <p class="p2"></p>
      <p class="p3">THT MAP OF YOUR OWN JOURNEY</p>
      <img :src="datas.planMap" alt="">
    </div>
    <div class="line3Box" v-if="_h5groupDetailsStrategy().ITINERARY_MAP && datas.planMap">
      <div class="line3" style="margin: 0.5rem 0;">
        <div class="yuan1" style="left: -0.06rem;"></div>
        <div class="yuan1" style="right: -0.06rem"></div>
      </div>
    </div>
    <div ref="targetElement" class="fourBox" style="padding:0 0.4rem">
      <p class="p1" style="margin-bottom: 0.1rem;">节点行程</p>
      <p class="p2"></p>
      <p class="p3">THT ITINERARY OF YOUR OWN JOURNEY</p>
      <main style="width: 100%;" id="main">
        <div class="Plan-Content" v-for="(day, dayIndex) in planData" :key="dayIndex">
          <div class="PC-One">
            <span class="day_icon PCO-Icon">D{{ day.planDay }}</span>
            <div class="PCO-City-Date">
              <span>{{ day.planDate | dateFormat }}</span>
              <!--              <img v-if="verifyThePresenceOfAttractions(day.budgetList)" class="Animation" @click="jumpMap(day)"-->
              <!--                src="@/assets/png/mapIcon.png" />-->
              <img class="Animation" @click="jumpMap(day)" src="@/assets/png/mapIcon.png" />
              <span style="flex: 3" @click="openMapNavigation(day)">{{ day.cityStr }}</span>
            </div>
          </div>
          <div>
          </div>
          <div class="PCO-Content" v-for="(budget, budgetIndex) in day.budgetList" :key="budgetIndex">
            <span style="display: flex;flex-direction: column; padding: 10px;"
              v-if="budget.state == 11 && (budget.resourceNameCn || budget.remark)">
              <span @click="jumpMap(day)">{{ budget.resourceNameCn }} {{ budget.timeStr }} </span>
              <span style="font-size: 12px;">{{ budget.remark }}</span>
            </span>
            <BudgetContent v-else :detailFlag="false" :budget="budget" :day="day" :individual="false">
            </BudgetContent>
          </div>
        </div>
      </main>
    </div>
    <div class="fourBox" v-if="show">
      <p class="p1" style="margin-bottom: 0.1rem;">行程描述</p>
      <p class="p2"></p>
      <p class="p3">THT TRIP OF YOUR OWN DESCRIPTIPN</p>
      <Ueditor v-show="show" @ueditorChange="loading = false" class="myUeditor" ref="ueditor" :readonly="true"
        :autoSyncData="false" :bar="bars" :enableContextMenu="false"></Ueditor>
    </div>
    <div class="line3Box" v-if="tuiTeDate.length > 0">
      <div class="line3" style="margin: 0.5rem 0;margin-top: 0;">
        <div class="yuan1" style="left: -0.06rem;"></div>
        <div class="yuan1" style="right: -0.06rem"></div>
      </div>
    </div>
    <div class="fourBox" v-if="tuiTeDate.length > 0 && _h5groupDetailsStrategy().FEATURED_RECOMMENDATIONS">
      <p class="p1" style="margin-bottom: 0.1rem;">行程特色</p>
      <p class="p2"></p>
      <p class="p3">THE ITINERARY OF YOUR OWN JOURNEY</p>
      <div class="fourBox spotBox" v-for="(item, index) in tuiTeDate" :key="index">
        <p class="p4">{{ item.resourceName }}</p>
        <div class="fenge">
          <div class="fengeLine"></div>
          <img style="width: 1rem;" :src="require('@/assets/png/fenge.jpeg')" alt="">
          <div class="fengeLine"></div>
        </div>
        <!--        <p class="p5">-->
        <!--          {{ item.word }}-->
        <!--        </p>-->
        <div class="Img-Show" v-for="(n, m) in item.tweets" :key="m">
          <p class="p5">
            {{ n.word }}
          </p>
          <img class="imgs3" v-for="(n1, m1) in n.picList" :key="m1" :src="n1" alt="">
        </div>
      </div>
    </div>
    <div v-if="JSON.stringify(resourceData) !== '{}'">
      <div class="line3Box" v-if="_h5groupDetailsStrategy().FEATURED_RECOMMENDATIONS && tuiTeDate.length > 0">
        <div class="line3" style="margin: 0.5rem 0;">
          <div class="yuan1" style="left: -0.06rem;"></div>
          <div class="yuan1" style="right: -0.06rem"></div>
        </div>
      </div>
      <div v-if="_h5groupDetailsStrategy().REFERENCE_RESTAURAN" class="fourBox" style="margin-bottom: 0.4rem;">
        <div style="position: relative;">
          <p class="p1 fn" style="margin-bottom: 0.05rem;">餐饮安排</p>
          <div class="fourBoxLine1"></div>
        </div>
        <p class="p3" style="margin-bottom: 0;">THE ITINERARY OF YOUR OWN JOURNEY</p>
        <div class="fenge">
          <div class="fengeLine"></div>
          <img style="width: 1rem;" :src="require('@/assets/png/fenge.jpeg')" alt="">
          <div class="fengeLine"></div>
        </div>
        <!-- <div class="meetInfo" v-if="resourceData.mealDates.length > 0 || resourceData.mealStandards.length > 0">
          <p class="meetInfoP1" v-for="(item, index) in resourceData.mealDates" :key="index">{{ item }}</p>
          <p class="meetInfoP2" v-for="(item, index) in resourceData.mealStandards" :key="index">{{ item }}</p>
        </div> -->
        <div v-if="false">
          <div class="featureTitle">
            <div class="featureTitleBox">
              <div class="featureTitleBoxLine"></div>
              特色餐
              <div class="featureTitleBoxLine"></div>
            </div>
            <p class="featureTitleP1">特色餐以最终安排为准</p>
          </div>
          <div class="featureDetail" v-for="(item, index) in resourceData.mealStandards" :key="index">
            <div class="featureDetailTitle">
              <div class="featureDetailTitleBox">
                <div style="top: 0;left: 0;" class="featureDetailTitleBoxChilc"></div>
                <div style="top: 0;right: 0;" class="featureDetailTitleBoxChilc"></div>
                <div style="bottom: 0;right: 0;" class="featureDetailTitleBoxChilc"></div>
                <div style="bottom: 0;left: 0;" class="featureDetailTitleBoxChilc"></div>
              </div>
              <p class="featureDetailTitleP1">{{ item.resourceName }}</p>
            </div>
            <img class="featureDetailImg" :src="item.imageUrl" alt="">
            <p class="featureDetailText"> {{ item.word }}</p>
          </div>
        </div>
      </div>
      <div v-if="resourceData.meals.length > 0 && _h5groupDetailsStrategy().REFERENCE_RESTAURANT" class="fourBox"
        style="margin-bottom: 0.4rem;">
        <div class="featureTitle" style="margin-bottom: 0.4rem;">
          <div class="featureTitleBox">
            <div class="featureTitleBoxLine"></div>
            参考餐厅
            <div class="featureTitleBoxLine"></div>
          </div>
          <p class="featureTitleP1">餐厅以最终安排为准</p>
        </div>
        <div class="canteenDetail">
          <div class="canteenDetailBox" v-for="(item, index) in resourceData.meals" :key="index">
            <div class="canteenDetailBoxL">
              <p class="canteenDetailBoxLp1">{{ item.cityNameCn ? item.cityNameCn : item.cityName }}</p>
              <p class="canteenDetailBoxLp2">{{ item.nameCn ? item.nameCn : itme.nameEn }}</p>
              <p v-if="item.otherResourceData && JSON.stringify(item.otherResourceData) !== '{}'"
                class="canteenDetailBoxLp3">{{ item.otherResourceData.cuisine }}</p>
              <p class="canteenDetailBoxLp4" v-if="item.score"> 口味分: <span class="canteenDetailBoxLp6">{{ item.score
                  }}</span> 星 </p>
              <p class="canteenDetailBoxLp5"><svg-icon style="height: 0.16rem;width: 0.18rem;"
                  iconClass="didian"></svg-icon>{{ item.address ? item.address : item.addressCn }}</p>
              <p class="canteenDetailBoxLp3">{{ item.introduction }}</p>
              <div class="canteenDetailBoxLine">
                <div class="canteenDetailBoxLineyuan"></div>
                <div v-if="index != resourceData.meals.length - 1" class="canteenDetailBoxLines"></div>
              </div>
            </div>
            <div class="canteenDetailBoxR" v-if="item.imageUrl">
              <img :src="item.imageUrl ? item.imageUrl : require('../../assets/png/zanwutupian.jpg')" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="line3Box" v-if="resourceData.hasMeal && _h5groupDetailsStrategy().REFERENCE_RESTAURANT">
        <div class="line3" style="margin-bottom: 0.5rem;">
          <div class="yuan1" style="left: -0.06rem;"></div>
          <div class="yuan1" style="right: -0.06rem"></div>
        </div>
      </div>
      <div v-if="resourceData.hasMeal && _h5groupDetailsStrategy().REFERENCE_RESTAURANT" class="fourBox"
        style="margin-bottom: 0.4rem;">
        <div class="featureTitle" style="margin-bottom: 0.4rem;">
          <div class="featureTitleBox">
            <div style="width:2.6rem" class="featureTitleBoxLine"></div>
            <p style="margin: 0.05rem 0;">参考菜式(中餐)</p>
            <div style="width:2.6rem" class="featureTitleBoxLine"></div>
          </div>
          <p class="featureTitleP1">菜式以最终安排为准</p>
        </div>
        <div class="meetImgBox">
          <div class="meetImgs" v-for="(item, index) in meetNamelist" :key="index">
            <img :src="require(`@/assets/png/plane/WechatIMG${index + 1}.jpeg`)" alt="">
            <p class="meetImgsP1">{{ item }}</p>
          </div>
        </div>
      </div>
      <div class="line3Box" v-if="resourceData.hasMeal && _h5groupDetailsStrategy().REFERENCE_RESTAURANT">
        <div class="line3" style="margin: 0.3rem 0 0.5rem 0;">
          <div class="yuan1" style="left: -0.06rem;"></div>
          <div class="yuan1" style="right: -0.06rem"></div>
        </div>
      </div>
      <div v-if="resourceData.hotels.length > 0 && _h5groupDetailsStrategy().REFERENCE_HOTEL">
        <div class="fourBox" style="margin-bottom: 0.2rem;">
          <div style="position: relative;">
            <p class="p1 fn" style="margin-bottom: 0.05rem;">参考酒店</p>
            <div class="fourBoxLine1"></div>
          </div>
          <p class="p3" style="margin-bottom: 0;">THE HOTELS OF YOUR OWN JOURNEY</p>
          <div class="fenge">
            <div class="fengeLine"></div>
            <img style="width: 1rem;" :src="require('@/assets/png/fenge.jpeg')" alt="">
            <div class="fengeLine"></div>
          </div>
          <div class="p6">
            酒店以最终安排为准
          </div>
          <div class="meetInfo" v-if="resourceData.hotelDates.length > 0 || resourceData.hotelStandards.length > 0">
            <p class="meetInfoP1" v-for="(item, index) in resourceData.hotelDates" :key="index">{{
              item
            }}</p>
            <p class="meetInfoP2" v-for="(item, index) in resourceData.hotelStandards" :key="item">{{
              item
            }}</p>
          </div>
        </div>
        <div class="hotelDetail">
          <div class="hotelDetailBox" v-for="(item, index) in resourceData.hotels" :key="index">
            <div class="imgBox">
              <img :src="item.imageUrl" alt="">
              <div class="star" v-if="item.star">
                <span v-for="(n, m) in item.star" :key="m">*</span>
              </div>
            </div>
            <p class="hotelDetailBoxP1">
              <svg-icon style="height: 0.16rem;width: 0.18rem;" iconClass="didian"></svg-icon>
              <span> {{ item.addressCn }}</span>
            </p>
            <p class="hotelDetailBoxP2">{{ item.nameCn }}</p>
            <!-- <p class="hotelDetailBoxP3">Booking评分:{{ item.source ? item.source : '暂无' }}</p> -->
            <p class="hotelDetailBoxP3">酒店综合评分:{{ item.score ? item.score : '暂无' }}</p>
            <p class="hotelDetailBoxP4">{{ item.introduction }}</p>
          </div>
        </div>
      </div>
      <div class="line3Box" v-if="resourceData.hotels.length > 0 && _h5groupDetailsStrategy().REFERENCE_HOTEL">
        <div class="line3" style="margin: 0.3rem 0 0.5rem 0;">
          <div class="yuan1" style="left: -0.06rem;"></div>
          <div class="yuan1" style="right: -0.06rem"></div>
        </div>
      </div>
      <div v-if="resourceData.bigTraffics.length > 0" class="fourBox" style="margin-bottom: 0.4rem;">
        <div style="position: relative;">
          <p class="p1" style="margin-bottom: 0.1rem;">交通安排</p>
          <div class="fourBoxLine1"></div>
        </div>
        <p class="p3" style="margin-bottom: 0;">THE TRANSPORTATION OF YOUR OWN JOURNEY</p>
        <div class="fenge" style="margin-bottom: 1.5rem;">
          <div class="fengeLine"></div>
          <img style="width: 1rem;" :src="require('@/assets/png/fenge.jpeg')" alt="">
          <div class="fengeLine"></div>
        </div>
        <div class="planeDetail">
          <div class="planeDetailBox" style="padding-top: 0.9rem;">
            <div class="iconBox" style="height: 1.7rem;">
              <img :src="require('@/assets/png/plane/aircraft.jpeg')" alt="">
            </div>
            <p>1.CA931 北京-法兰 2020-0630</p>
            <p>2.CA931 北京-法兰 2020-0630</p>
            <p>3.CA931 北京-法兰 2020-0630</p>
            <p>4.CA931 北京-法兰 2020-0630</p>
          </div>
          <div class="planeDetailBox">
            <div class="iconBox">
              <img :src="require('@/assets/png/plane/train.jpeg')" alt="">
            </div>
            <p>1.CA931 北京-法兰 2020-0630</p>
            <p>2.CA931 北京-法兰 2020-0630</p>
            <p>3.CA931 北京-法兰 2020-0630</p>
            <p>4.CA931 北京-法兰 2020-0630</p>
          </div>
          <div class="planeDetailBox">
            <div class="iconBox">
              <img :src="require('@/assets/png/plane/bus.jpeg')" alt="">
            </div>
            <p>2023.11.15</p>
            <p>2023.11.15</p>
            <p>2023.11.15</p>
            <p>2023.11.15</p>
          </div>
          <div class="planeDetailBox">
            <div class="iconBox">
              <img :src="require('@/assets/png/plane/steamer.jpeg')" alt="">
            </div>
            <p>1.CA931 北京-法兰 2020-0630</p>
            <p>2.CA931 北京-法兰 2020-0630</p>
            <p>3.CA931 北京-法兰 2020-0630</p>
            <p>4.CA931 北京-法兰 2020-0630</p>
          </div>
          <div class="planeDetailBox">
            <div class="iconBox">
              <img :src="require('@/assets/png/plane/car.jpeg')" alt="">
            </div>
            <p>2023.11.15</p>
            <p>2023.11.15</p>
            <p>2023.11.15</p>
            <p>2023.11.15</p>
          </div>
        </div>
      </div>
    </div>
    <router-view></router-view>
    <map-navigation ref="mapNavigation" :pointMap="pointMap"></map-navigation>
  </div>
</template>

<script>
import BudgetContent from '@/views/plan/children/BudgetContent'
import { changePlanInfo } from "./model/PlanInfo";
import Api from "@/components/Api/index.js"
import ResourceApi from "@/components/Api/resource.js"
import PlanApi from "@/components/Api/planApi.js"
import { countryNum, getUrlParams } from '@/utils/unit'
import { getDateYearSubNew, getDateStr } from '@/utils/DateUtil'
import { mapMutations, mapState } from "vuex";
import { schemeDetailsPriceDisplayStrategy, priceDisplayWithinGroupDetailsStrategy, h5groupDetailsStrategy } from "@/views/plan/model/PriceShow";
import { Notify } from "vant";
import TextRoll from '../../components/TextRoll.vue';
import Ueditor from "@/components/Editor/Ueditor.vue";
import MapNavigation from "@/components/MapNavigation.vue";
import axios from "axios";
export default {
  components: {
    BudgetContent,
    TextRoll,
    Ueditor,
    MapNavigation
  },
  data() {
    return {
      bars: [],
      show: false,
      // 外侧滚动条位置
      outScrollTop: 0,
      mapCityArr: [],//城市地图数组
      // 内侧滚动条位置
      insideScrollTop: 0,
      tuiTeDate: [],//推特数据
      pointMap: [],
      tuiteFirstImg: '',//推特首图
      resourceData: {},//行程特色后面的数据
      countryNum,
      meetNamelist: ['烤鸡翅', '土豆烧牛肉', '宫保鸡丁', '清蒸鱼', '蒜蓉青菜', '萝卜莲汤'],
      // 用户及公司信息展示
      userInfo: {
        "adressRegistration": "",
        "citySize": 0,
        "countrySize": 0,
        "customerMessage": "",
        "mechanismName": "",
        "slogan": "",
        "startTime": "",
        "userInfoResult": {
          "birthday": "",
          "career": "",
          "citySize": 0,
          "countrySize": 0,
          "customerMessage": "",
          "email": "",
          "loginAccount": "",
          "professionDate": "",
          "sex": "",
          "userName": "",
          "wechat": "",
          "weibo": ""
        }
      },
      userId: -5,
      mechanismId: -5,
      planData: [],
      datas: {},//渲染的数据
      loading: false,
      qyNationalImageList: [],
      datumList: [
        {
          code: 0,
          name: '案例',
          num: 12
        },
        {
          code: 1,
          name: '经验/年',
          num: 6
        },
        {
          code: 'countrySize',
          name: '国家',
          num: 600
        },
        {
          code: 'citySize',
          name: '城市',
          num: 4560
        },
      ],
      btnList: [
        {
          icon: require('@/assets/png/plane/WechatIMG301.jpeg'),
          name: '方案概括'
        },
        {
          icon: require('@/assets/png/plane/WechatIMG302.jpeg'),
          name: '行程地图'
        },
        {
          icon: require('@/assets/png/plane/WechatIMG303.jpeg'),
          name: '详细报价'
        },
        {
          icon: require('@/assets/png/plane/WechatIMG304.jpeg'),
          name: '价格说明'
        }

      ],
      btnList1: ['省时', '省力', '省钱']
    }
  },
  computed: {
    ...mapState('collection', ['budgetMainId', 'planId', 'priceDisplay', 'programmeId', 'isPay', 'inquiryId']),
    calculationPeriod() {
      return function (userInfoResult, item) {
        if (item.code === 1) {
          if (userInfoResult.professionDate === '') {
            return 0;
          }
          var end = getDateStr(new Date().getTime());
          var start = getDateStr(new Date(userInfoResult.professionDate).getTime())
          let startYear = start.split("-")[0]
          let endYear = end.split("-")[0]
          return Number(endYear) - Number(startYear)
        } else if (userInfoResult[item.code]) {
          // TODO 暂时先这么返回 有数据了再加判断
          return userInfoResult[item.code]
        } else {
          return item.num
        }
      }
    },
    //计算字体大小
    fontSize() {
      return function (text, maxChars, fontSize) {
        console.log(text, maxChars, fontSize, '看坎坎坷坷');
        if (text) {
          const textLength = text.toString().length;
          if (textLength > maxChars) {
            const maxTotalWidth = maxChars * fontSize;
            const newFontSize = maxTotalWidth / textLength;
            return newFontSize
          } else {
            return fontSize
          }
        } else {
          return fontSize
        }
      }
    },
    /**
     * 计算公司成立多少年
     * @param dateOfIncorporation 公司成立日期
     * @returns {(function())|*}
     */
    companyEstablishmentYear(dateOfIncorporation) {
      return function (dateOfIncorporation) {
        if (dateOfIncorporation === '') {
          return 0;
        }
        var end = getDateStr(new Date().getTime());
        var start = getDateStr(new Date(dateOfIncorporation).getTime())
        let startYear = start.split("-")[0]
        let endYear = end.split("-")[0]
        return Number(endYear) - Number(startYear)
      }
    },

    _obtainPriceDisplayStatus() {
      return function () {
        return schemeDetailsPriceDisplayStrategy(this.priceDisplay.programme.checkProgrammeItems)
      }
    },
    _isDetailPrice() {
      return function () {
        return priceDisplayWithinGroupDetailsStrategy(this.priceDisplay.offer.checkOfferItems)
      }
    },
    _h5groupDetailsStrategy() {
      return function () {
        return h5groupDetailsStrategy(this.priceDisplay.programme.checkProgrammeItems)
      }
    },
    verifyThePresenceOfAttractions() {
      return function (budgetList) {
        console.log("budgetList", budgetList)
        if (typeof budgetList === 'undefined') {
          return false
        }
        let v = budgetList.filter(x => x.state === 2)
        if (v.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    }
  },
  watch: {},
  methods: {
    ...mapMutations('collection', ['SET_DAY_DATA']),
    handlerSeeImg(arr) {
      this.$viewerApi({
        images: arr,
      })
    },
    // 打开地图导航
    openMapNavigation(day) {
      this.pointMap = []
      day.cityList.forEach(item => {
        let map = { la: item.latitude, ln: item.longitude, pointName: item.cityName }
        this.pointMap.push(map)
      })
      this.$refs.mapNavigation.open(this.pointMap)
      console.log("openMapNavigation", day)
    },
    //前往支付
    goToPay() {
      wx.miniProgram.redirectTo({
        url: '/orderPages/orderPay/orderPay'
      });
    },
    jumpPoint(item) {
      let that = this
      const map = {
        '方案概括': function () {
          console.log(that.$refs.targetElement, '看看元素节点');
          const targetElement = that.$refs.targetElement
          targetElement.scrollIntoView()
        },
        '行程地图': () => this.$router.push({ name: 'PlanBaiduMap', params: { citys: this.mapCityArr } }),
        '详细报价': () => this.$router.push({ path: '/PlanShare', query: { planId: this.planId, budgetMainId: this.budgetMainId, bar: true } }),
        '价格说明': () => this.$router.push({ name: 'PriceExplain', query: { mechanismId: this.mechanismId } }),
      }
      if (map[item]) {
        map[item]()
      }
    },
    jumpMap(day) {
      this.SET_DAY_DATA(day)
      this.$router.push({ path: '/planDays' })
    },
    //字符截取
    strSub(str) {
      if (str) {
        let lastSpaceIndex = str.lastIndexOf(" ");
        let beforeLastSpace = str.substring(0, lastSpaceIndex);
        let afterLastSpace = str.substring(lastSpaceIndex + 1);
        return [beforeLastSpace, afterLastSpace]
      }
    },
    _getData() {
      this.loading = true
      changePlanInfo({ planId: this.planId, budgetMainId: this.budgetMainId, inquiryId: this.inquiryId }).then(pro => {
        if (pro.planDetail.planCharatDesc !== '') {
          this.show = true
          this.$nextTick(() => {
            this.$refs.ueditor.setUeContent(pro.planDetail.planCharatDesc)
          })
        }
        this.mapCityArr = []
        let cityArr = [...(pro._allCitys.filter(u => u.longitude !== '' && u.latitude !== ''))]
        let _filterCitys = cityArr.filter(u => u.countryId == 8)
        if (_filterCitys.length < 3) {
          this.mapCityArr.push(...cityArr.filter(u => u.countryId != 8))
        } else {
          this.mapCityArr.push(...cityArr)
        }
        this.datas = pro.planDetail
        console.log(this.datas, 'sadasdasdasd');
        // 方案详情内只显示 isCheck = 1 的数据
        pro._platDays.forEach(item => {
          if (item.budgetList) {
            item.budgetList = item.budgetList.filter(x => x.isCheck && x.isCheck === 1)
          }
        })
        pro.allArr = pro.allArr.filter(n => n.resourceId != 0)
        const idSet = new Set(pro.allArr.map(obj => obj.resourceId));
        const resourceIdArr = [...idSet];
        console.log(pro, 'dasdadasdasda');
        // this.getQueenData(resourceIdArr)
        pro._platDays.forEach(i => {
          if (i.budgetList.length === 0) {
            i.budgetList = i.budgetListFs
          }
        })
        this.planData = pro._platDays
        console.log(this.planData, '行程节点数据');
        this.getQueenData({ budgetMainId: this.budgetMainId })
        this.getTuiImgs({ resourceIds: resourceIdArr, budgetMainId: this.budgetMainId })
        this.loading = false
        this.userId = pro.userId
        this.mechanismId = pro.mechanismId
        this.getPlanImgList()
        this.qyNationalImage()
      })
    },
    //行程特色后面的渲染数据
    getQueenData(obj) {
      new PlanApi("plan").referResource(obj).then((result) => {
        if (result.status === 200) {
          console.log(result, '看看数据9999');
          this.resourceData = result.data
        }
      }).catch((err) => {
        Notify({ type: 'danger', message: '系统异常！' });
      });
    },
    blobToDataURI(blob, callback) {
      let reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = function (e) {
        callback(e.target.result);
      }
    },
    // 获取穷游国家图片
    qyNationalImage() {
      new PlanApi("plan").qyNationalImage({ planId: this.planId, budgetMainId: this.budgetMainId })
        .then(res => {
          if (res.status === 200) {
            if(res.data.length > 0) {
              this.qyNationalImageList = res.data[0]
            }
          }
        })
    },
    //获取推特图列表
    getTuiImgs(obj) {
      new PlanApi("plan").tweetList(obj).then((result) => {
        if (result.status === 200) {
          this.tuiTeDate = result.data
          let arr = []
          this.tuiTeDate.forEach(item => {
            arr = item.tweets.filter(n => n.picList.length > 0)
          })
          if (arr.length > 0) {
            this.tuiteFirstImg = arr[0].picList[0]
          }
        }
      }).catch((err) => {
        Notify({ type: 'danger', message: '系统异常！' });
      });
    },
    getPlanImgList() {
      new Api("user").getPlanCompanyInfo({ id: this.userId, mechanismId: this.mechanismId }).then((result) => {
        if (result.status === 200) {
          console.log(result, '看看书看得开');
          this.userInfo = result.data
        }
      }).catch((err) => {
        console.log("err", err)
        // Notify({ type: 'danger', message: '系统异常！' });
      });
    },
  },
  created() {

  },
  mounted() {
    this._getData()
    window.addEventListener('popstate', function (e) {
      wx.miniProgram.navigateBack()
    },
      false
    )
  },
  //离开路由之前执行的函数,可用于页面的反向传值，页面跳转。
  beforeRouteLeave(to, from, next) {
    // 记录滚动条位置
    this.outScrollTop = document.getElementsByClassName("container")[0].scrollTop || 0;
    this.insideScrollTop = document.getElementById('main').scrollTop || 0;
    next()
  },
  // 组件激活时触发
  activated() {
    // 还原滚动条位置
    document.getElementsByClassName("container")[0].scrollTop = this.outScrollTop || 0; //组件写法
    document.getElementById('main').scrollTop = this.insideScrollTop || 0; //全局写法
  }
}
</script>
<style lang='scss' scoped>
/deep/ .mod-demo-ueditor {
  width: 100%;
}

/deep/ .edui-default .edui-editor {
  border: none !important;
}

/deep/ .edui-editor-toolbarbox .edui-default {
  display: none;
}

.myUeditor {
  top: 4px;

  /deep/.edui-editor {
    border: none;
  }

  /deep/.edui-editor-bottomContainer {
    display: none;
  }

  /deep/ .firstRow {
    background-color: red;
  }
}

.container {

  //overflow-y: scroll;
  //height: 90vh;
  .lineBox {
    white-space: pre-line;

    .p1 {
      font-size: 0.35rem;
      color: #285ca2;
      font-weight: 700;
    }

    .p2 {
      margin-bottom: 0.1rem;
      white-space: pre-line;
    }
  }

  .infoBoxB {
    display: flex;
    align-items: center;
    width: 100%;
    overflow-x: scroll;

    .infoBoxBImgs {
      width: 2rem;
      height: 2rem;
      margin-right: 0.1rem;
      object-fit: cover;

      &:nth-last-child(1) {
        margin-right: 0;
      }
    }
  }

  .titleImg {
    width: 100%;
    height: 5rem;
    border: 0.2rem solid #E84A18;
    overflow: hidden;
    margin-bottom: 0.4rem;

    img {
      width: 100%;
      height: 100%;
      //object-fit: scale-down;
    }
  }

  .line3Box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 0;

    .line3 {
      height: 0.15rem;
      width: 98%;
      background-color: #E84A18;
      position: relative;

      .yuan1 {
        height: 0.4rem;
        width: 0.4rem;
        border-radius: 50%;
        background-color: #E84A18;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

    }
  }

  .oneBox {

    .p1 {
      font-size: 0.62rem;
      text-align: center;
      font-family: 思源宋体Blod;
      // margin-bottom: 0.2rem;
    }

    .p3 {
      font-size: 0.4rem;
      text-align: center;
      color: gray;
      font-family: 思源宋体 Medium;
      margin-bottom: 0.4rem;
    }

    .p2 {
      font-size: 0.285rem;
      color: #000;
      text-align: center;
    }

    .line {
      width: 100%;
      height: 1px;
      border-bottom: 1px solid #D4552E;
      margin: 0.3rem 0;
    }

    .prices {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.2rem;

      .priceP1 {
        font-weight: bold;
        // font-family: 思源宋体Blod;

        span {
          font-size: 0.3rem;
        }
      }
    }

    .planeDetail {
      display: flex;
      align-items: center;
      justify-content: center;

      .planeDetailL {
        .yuan {
          height: 1.2rem;
          width: 1.2rem;
          border-radius: 50%;
          border: 2px solid #000;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 0.2rem;

          .fx {
            width: 0.75rem;
            height: 0.75rem;
            border-radius: 0.1rem;
            background-color: #000;
            color: #fff;
            line-height: 0.9rem;
            font-size: 0.9rem;
            text-align: right;
            font-weight: 500;
            padding-right: 0.07rem;
          }
        }
      }

      .planeDetailR {
        font-size: 0.12rem;

        .planeDetailRp1 {
          color: #5A5A5A;
          font-weight: bold;
          font-size: 0.26rem;
          margin-bottom: 0.1rem;
        }

        .planeDetailRp2 {
          color: #000;
          font-size: 0.25rem;
          margin-bottom: 0.1rem;
        }

        .planeDetailRp3 {
          font-size: 0.25rem;
          color: #000;
        }
      }
    }

    .btnBox {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 0.5rem;

      .btn {
        display: flex;
        align-items: center;

        .text {
          width: 0.55rem;
          color: #E84A18;
          font-weight: bold;
          font-size: 0.26rem;
        }

        .line1 {
          height: 0.7rem;
          width: 1px;
          border-right: 1px solid #000;
          margin: 0.3rem;
        }

      }
    }
  }

  .twoBox {
    padding: 0 0.35rem;
    margin-bottom: 0.4rem;
    text-align: center;

    .p1 {
      color: #172959;
      font-size: 0.35rem;
      font-family: 思源宋体 Medium;
      font-weight: bold;
      margin-bottom: 0.3rem;
    }

    .p2 {
      font-size: 0.35rem;
      font-weight: bold;
      margin-bottom: 0.1rem;
    }

    .p3 {
      font-size: 0.25rem;
      color: #E84A18;
      margin-bottom: 0.3rem;
    }

    .p4 {
      font-size: 0.12rem;
      transform: scale(0.9);
      margin-bottom: 0.2rem;
    }

    .p5 {
      font-weight: bold;
      margin-bottom: 0.1rem;

      span {
        font-size: 0.58rem;
      }
    }

    .p6 {
      margin-bottom: 0.4rem;
      font-size: 0.27rem;
    }

    .p7 {
      color: #5B5B5B;
      font-size: 0.25rem;
      margin-bottom: 0.4rem;
    }
  }

  .threeBox {
    padding: 0 0.35rem;

    .infoBox {
      .infoBoxT {
        margin-bottom: 0.4rem;

        // justify-content: center;
        .infoBoxTInfo {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 0.4rem;

          .infoBoxTInfoL {
            margin-right: 0.2rem;

            .infoBoxTimgs {
              height: 2.2rem;
              width: 2.2rem;
              border-radius: 50%;
            }
          }

          .infoBoxTInfoR {
            width: 3rem;

            .p1 {
              font-size: 0.58rem;
              text-align: center;
              font-family: 思源宋体Blod;
              margin-bottom: 0.1rem;
            }

            .infoBoxTRTItle {
              height: 0.6rem;
              width: 1.8rem;
              margin: 0 auto;
              width: 95%;
              background-color: #E84A18;
              color: #fff;
              text-align: center;
              line-height: 0.6rem;
              font-size: 0.27rem;
              position: relative;
              overflow: hidden;
              margin-bottom: 0.1rem;

              .zhedian {
                position: absolute;
                height: 0.6rem;
                width: 0.6rem;
                background-color: #fff;
                transform: rotate(-45deg);
                top: 0;
              }
            }

            .relationLineBox {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 0.15rem;

              .svg1 {
                height: 0.4rem;
              }

              &:nth-last-child(1) {
                margin-bottom: 0;
              }

              .relationLineBoxP1 {
                font-size: 0.28rem;
                color: #5D5D5D;
              }
            }
          }
        }

        .datumBox {
          display: flex;
          align-items: center;
          justify-content: center;

          .datumBoxLine {
            height: 1rem;
            width: 1px;
            border-right: 1px solid #D8D8D8;
          }

          .datumBoxLineBox {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            // flex: 1;
            width: 1.8rem;

            .datumBoxLineP1 {
              font-size: 0.48rem;
            }

            .datumBoxLineP2 {
              font-size: 0.2rem;
            }


          }
        }

        .datumBox {
          .datumBoxLine {}
        }
      }

      .infoBoxC {
        font-size: 0.26rem;
        color: #000;
        margin-bottom: 0.4rem;
      }
    }
  }

  .fourBox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .fourBoxLine1 {
      height: 0.27rem;
      width: 3rem;
      left: 50%;
      transform: translateX(-50%);
      background-color: #EFC4B4;
      position: absolute;
      bottom: 0;
      z-index: -1;
    }

    .spotBox {
      // margin-bottom: 0.2rem;

      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }

    .meetInfo {
      text-align: center;
      border: 1px solid #000;
      width: 6.4rem;
      padding: 0.1rem 0;
      margin-bottom: 0.3rem;

      .meetInfoP1 {
        font-size: 0.25rem;
        color: #2E3030;
        font-weight: bold;
      }

      .meetInfoP2 {
        font-weight: bold;
        font-size: 0.22rem;
      }
    }

    .meetImgBox {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 0.58rem;

      .meetImgs {
        margin-bottom: 0.2rem;
        width: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          height: 3.2rem;
          width: 100%;
          object-fit: cover;
        }

        .meetImgsP1 {
          margin-top: 0.2rem;
          font-size: 0.12rem;
          transform: scale(0.85);
        }
      }
    }

    .planeDetail {
      .planeDetailBox {
        width: 6.4rem;
        border: 2px solid #D99A64;
        position: relative;
        padding: 0.6rem 0 0.2rem 0;
        margin-bottom: 1.2rem;
        text-align: center;

        .iconBox {
          width: 3.5rem;
          background-color: #fff;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: -0.85rem;
          display: flex;
          justify-content: center;
          height: 1.3rem;

          img {
            width: 2rem;
            height: 100%;
          }

        }

        p {
          color: #6D6D6D;
          font-weight: bold;
          margin-bottom: 0.05rem;
        }
      }
    }

    .featureTitle {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .featureTitleBox {
        // padding: 0.05rem 0;
        font-weight: bold;
        font-size: 0.36rem;
        margin-bottom: 0.05rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        .featureTitleBoxLine {
          width: 1.2rem;
          height: 1px;
          border-bottom: 2px solid #000;
        }
      }

      .featureTitleP1 {
        font-size: 0.12rem;
        color: #2E3030;
        transform: scale(0.8);
      }
    }

    .canteenDetail {
      border-right: 0.2rem solid #fff;
      border-left: 0.2rem solid #fff;
      width: 100%;
      background-color: #F1F1F2;
      padding: 0.3rem 0.3rem 0.6rem 0.6rem;
      margin-bottom: 0.25rem;

      .canteenDetailBox {
        height: 3.1rem;

        &:nth-child(even) {
          flex-direction: row-reverse;
        }

        width: 100%;
        background-color: #fff;
        display: flex;
        position: relative;
        margin-bottom: 0.3rem;

        &:nth-last-child(1) {
          margin-bottom: 0;
        }

        .canteenDetailBoxLine {
          position: absolute;
          left: -0.45rem;
          top: 45%;
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 100%;

          .canteenDetailBoxLineyuan {
            min-height: 0.3rem;
            min-width: 0.3rem;
            border-radius: 50%;
            background-color: #8F3A1F;
          }

          .canteenDetailBoxLines {
            margin-top: 0.25rem;
            height: 100%;
            width: 1px;
            border-right: 2px solid #8F3A1F;
          }
        }

        .canteenDetailBoxL {
          width: 48%;
          padding: 0.15rem 0.2rem 0.3rem 0.2rem;

          p {
            margin-bottom: 0.05rem;

          }


          .canteenDetailBoxLp1 {
            font-size: 0.3rem;
            font-weight: bold;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: nowrap;
          }

          .canteenDetailBoxLp2 {
            font-size: 0.25rem;
            color: #D45931;
          }

          .canteenDetailBoxLp3 {
            font-size: 0.21rem;
            color: #CE4F65;
            width: 100%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
          }

          .canteenDetailBoxLp4 {
            font-size: 0.21rem;
            color: #000;
          }

          .canteenDetailBoxLp5 {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 0.21rem;
            color: #000;
          }

          .canteenDetailBoxLp6 {
            font-size: 0.21rem;
            color: #CE4F65;
          }
        }

        .canteenDetailBoxR {
          flex: 1;

          img {
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }


    img {
      // height: 4rem;
      width: 100%;
      object-fit: cover;
    }

    .p1 {
      font-size: 0.47rem;
      font-family: 思源宋体Blod;
      font-weight: bold;
    }

    .fn {
      font-family: none !important;
      font-weight: 600;
    }

    .p2 {
      width: 0.75rem;
      height: 1px;
      border-bottom: 2px solid #000;
      margin-bottom: 0.2rem;
    }

    .p3 {
      color: #363636;
      font-family: 思源宋体 Medium;
      margin-bottom: 0.25rem;
      font-size: 0.12rem;
      transform: scale(0.9);
    }

    .p4 {
      font-size: 0.42rem;
      padding: 0 0.9rem;
      color: #db4e1a;
      text-align: center;
      font-weight: bold;
      font-family: 思源宋体Blod;
    }

    .fenge {
      margin: 0.15rem 0;
      display: flex;
      align-items: center;

      img {
        margin: 0 0.1rem;
        width: 1rem;
      }

      .fengeLine {
        width: 2rem;
        height: 1px;
        border-bottom: 1px solid #000;
      }
    }

    .p5 {
      padding: 0 0.9rem;
      text-align: center;
      color: #7E7E7E;
      margin-bottom: 0.1rem;
    }

    .p6 {
      font-size: 0.12rem;
      margin-bottom: 0.9rem;
    }

  }

  .day_icon {
    width: 0.5rem;
    height: 0.5rem;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAACUklEQVRYR+2Xy2vUUBTGv3NnyhSlUrSVJOMjxZV20UL/gSkW7KRV3Lh2Vt0LdW3Fre6tG3Wj0KXTTkpRO3sf4EaKVDqMTjKtKCoo83ByJKHJvEp7pwSmi7kQuLm533d+OfcmOSF0uVGX4+NAgC/TSqLP4TfK2vYfWdiNa0MDxyvRCRndvgDWjDoHxqLDPH/GLD6QBfhqKCkBeiyj2xegYCgLBLrD4LvxTHFBFqATXRvA1nV9MFoqjbvBhMBNAqUY/MRx8FQWYC/dMaq8P2X++N3q0QRgGdoSwDdkA3U6j8DpKju3zpk7n31tAGAZKgeGjOxuXwdBByMHeIdsa9cREr6YBc7Hl+28e+4BWEl1EYQ5d62JKK2t2O/c8U7WspFsL509reiOQMrdUwCtaBlrtg5gqDsAhol5VDWLH32zb1eG1Kroe8ZEj+IZ67ns7duzisE1ut2qcx/piKB1L7DDI+pqMUfBICOrmfakbJDDzrOS6joIiZrDk2dXi9kAgEEfQJg/rLG0jnGfwGNtANIGIU30X1L1JQD9BHiwwf9lSLF8m6ndjuO+Yir/nMv62vbro7MH0O1N2APoZaCXgV4GupaB78mTJ8oU+9X4jQ75GxDYWTPqBBhvvYEIXdTS1oZXEdmG8oJBV1srojBBmisifCr/jY2NZHMlDyCfPH0hSpHNIGC9JgyPoaEmFEJMKcuFV0FJ5mUhqVyCoCVmjIYXtdmJiC1muqdl7If+laayfCuh98cGKnqt6ihhQ8Si0cJwrT9P5ma50fvAf8OwQVr9ug7wH/8HmjAEHOudAAAAAElFTkSuQmCC) no-repeat;
    background-size: 0.5rem 0.5rem;
    font-weight: bold;
    color: #db4e1a;
    text-align: center;
    line-height: 0.66rem;
    font-size: 0.2rem;
  }

  #main {
    padding: 20px;
    // overflow-y: scroll;

    .Plan-Content {
      border-left: 0.03rem dashed #db4e1a;

      .PCO-Content {
        box-shadow: 0px 0.01rem 0.1rem 0px #DDDDDD;
        width: 98%;
        margin-left: 15px;
        position: relative;
        border-radius: 0.2rem;
        margin-bottom: 20px;
        margin-top: 10px;
      }

      .PC-One {
        background: white;
        display: flex;
        position: relative;
        left: -0.25rem;

        .PCO-Icon {}

        .PCO-City-Date {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: absolute;
          left: 0.7rem;
          top: 50%;
          width: 100%;
          transform: translateY(-41%);

          >span:nth-child(1) {
            color: #333;
            margin: 0 0.15rem;
            font-size: 0.27rem;
            white-space: nowrap;
            flex: 2;
          }

          >span:nth-child(2) {
            width: 8rem;
            display: inline-block;
            width: 4.25rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: #333;
            font-weight: bold;
          }
        }
      }
    }
  }

  .featureDetail {
    &:nth-child(odd) {
      .featureDetailTitle {
        justify-content: end;
        flex-direction: row-reverse;
      }

      .featureDetailTitleP1 {
        margin-right: 0.2rem;
      }

      .featureDetailText {
        text-align: right;
      }
    }

    .featureDetailTitle {
      display: flex;
      align-items: center;
      padding: 0 0.5rem;
      margin-bottom: 0.25rem;

      .featureDetailTitleBox {
        width: 0.7rem;
        height: 0.7rem;
        position: relative;
        margin-right: 0.2rem;

        .featureDetailTitleBoxChilc {
          width: 0.27rem;
          height: 0.27rem;
          background-color: #D4562E;
          position: absolute;
        }
      }

      .featureDetailTitleP1 {
        color: #D4562E;
        font-size: 0.4rem;
        font-weight: bold;
      }
    }

    .featureDetailImg {
      width: 100%;
      max-height: 4rem;
      margin-bottom: 0.2rem;
    }

    .featureDetailText {
      padding: 0 0.3rem;
      margin-bottom: 0.5rem;
      // &:nth-last-child(1) {
      //     margin-bottom: 0;
      // }
    }
  }

  .hotelDetail {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 0.58rem;

    .hotelDetailBox {
      p {
        margin-bottom: 0.05rem;
      }

      width: 48%;
      margin-bottom: 0.2rem;

      .imgBox {
        position: relative;

        img {
          width: 100%;
          margin-bottom: 0.1rem;
          height: 2rem;
          object-fit: cover;
        }

        .star {
          width: 1rem;
          height: 0.5rem;
          background-color: rgba(166, 74, 46, 0.7);
          position: absolute;
          bottom: 0.3rem;
          right: 0.1rem;
          text-align: center;
          line-height: 0.65rem;
          font-size: 0.45rem;
          color: #F3ECA1;
        }
      }


      .hotelDetailBoxP1 {
        color: #000;
        font-size: 0.2rem;
      }

      .hotelDetailBoxP2 {
        font-weight: bold;
        font-size: 0.1rem;
        margin-bottom: 0.15rem;
        word-wrap: break-word;
        word-break: break-all;
      }

      .hotelDetailBoxP3 {
        font-size: 0.1rem;
        color: #515153;
      }

      .hotelDetailBoxP4 {
        font-size: 0.2rem;
        color: #D65C35;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

/deep/ .Animation {
  position: relative;
  //right: 12px;
  margin-right: 0.15rem;
  width: 24px !important;
  height: 24px;
  animation-name: share;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-duration: 1s;
}

@keyframes share {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.Img-Show {
  margin-bottom: 0.5rem;

  &:nth-last-child(1) {
    margin-bottom: 0.33rem;
  }

  .imgs3 {
    margin-bottom: 0.1rem;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  .p5 {
    padding: 0 0.9rem;
    text-align: center;
    color: #7E7E7E;
    margin-bottom: 0.1rem;
  }
}
</style>
