var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _vm.isPay
        ? _c(
            "van-button",
            {
              staticStyle: {
                margin: "0.1rem 0",
                width: "100%",
                position: "sticky",
                top: "0",
                "z-index": "99",
              },
              attrs: { type: "primary" },
              on: { click: _vm.goToPay },
            },
            [_vm._v("前往支付")]
          )
        : _vm._e(),
      _vm.loading
        ? _c("van-loading", {
            staticStyle: {
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              top: "50%",
            },
            attrs: { size: "42", type: "spinner", color: "#D6562E" },
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "titleImg",
          on: {
            click: function ($event) {
              _vm.handlerSeeImg([
                _vm.datas.imageUrl
                  ? _vm.datas.imageUrl
                  : _vm.tuiteFirstImg
                  ? _vm.tuiteFirstImg
                  : _vm.qyNationalImageList[0]
                  ? _vm.qyNationalImageList[0]
                  : require("@/assets/png/defalt.jpg"),
              ])
            },
          },
        },
        [
          _c("img", {
            attrs: {
              src: _vm.datas.imageUrl
                ? _vm.datas.imageUrl
                : _vm.tuiteFirstImg
                ? _vm.tuiteFirstImg
                : _vm.qyNationalImageList[0]
                ? _vm.qyNationalImageList[0]
                : require("@/assets/png/defalt.jpg"),
              alt: "",
            },
          }),
        ]
      ),
      _c("div", { staticClass: "oneBox" }, [
        _vm._h5groupDetailsStrategy().F
          ? _c("div", { staticStyle: { padding: "0 0.8rem" } }, [
              _c("div", { staticStyle: { padding: "0 0.1rem" } }, [
                _vm.datas.planName
                  ? _c(
                      "div",
                      { staticClass: "p1" },
                      [
                        _c("TextRoll", {
                          attrs: { text: _vm.strSub(_vm.datas.planName)[0] },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                JSON.stringify(_vm.datas) !== "{}" && _vm.datas.planName
                  ? _c("p", { staticClass: "p3" }, [
                      _vm._v(_vm._s(_vm.strSub(_vm.datas.planName)[1])),
                    ])
                  : _vm._e(),
                _c("p", { staticClass: "p2" }, [
                  _vm._v(_vm._s(_vm.datas.budgetName)),
                ]),
              ]),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "flex-direction": "column",
                    "justify-content": "center",
                  },
                },
                [
                  _c("div", { staticClass: "line" }),
                  _vm.datas.budgetDetailResult
                    ? _c("div", { staticClass: "prices" }, [
                        _vm._obtainPriceDisplayStatus().ALL ||
                        _vm._obtainPriceDisplayStatus().SINGLE_PERSON
                          ? _c(
                              "p",
                              {
                                staticClass: "priceP1",
                                staticStyle: { "margin-right": "0.4rem" },
                              },
                              [
                                _c("span", [_vm._v("¥")]),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      display: "inline-block",
                                      margin: "0 0.05rem",
                                    },
                                    style: {
                                      fontSize:
                                        _vm.fontSize(
                                          parseInt(
                                            _vm.datas.budgetDetailResult
                                              .saleTotalPrice /
                                              _vm.datas.useNumber
                                          ),
                                          3.5,
                                          0.6
                                        ) + "rem",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          (
                                            _vm.datas.budgetDetailResult
                                              .saleTotalPrice /
                                            _vm.datas.useNumber
                                          ).toFixed(2)
                                        )
                                    ),
                                  ]
                                ),
                                _c("span", [_vm._v("/人")]),
                              ]
                            )
                          : _vm._e(),
                        _vm._obtainPriceDisplayStatus().ALL ||
                        _vm._obtainPriceDisplayStatus().GROUP_PRICE
                          ? _c("p", { staticClass: "priceP1" }, [
                              _c("span", [_vm._v("¥")]),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    margin: "0 0.05rem",
                                  },
                                  style: {
                                    fontSize:
                                      _vm.fontSize(
                                        parseInt(
                                          _vm.datas.budgetDetailResult
                                            .saleTotalPrice /
                                            _vm.datas.useNumber
                                        ),
                                        3.5,
                                        0.6
                                      ) + "rem",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.datas.budgetDetailResult
                                          .saleTotalPrice
                                          ? _vm.datas.budgetDetailResult
                                              .saleTotalPrice
                                          : 0
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("span", [_vm._v("/团")]),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]
              ),
              _c("div", { staticClass: "planeDetail" }, [
                _vm._m(0),
                _c("div", { staticClass: "planeDetailR" }, [
                  _c("p", { staticClass: "planeDetailRp1" }, [
                    _vm._v(
                      _vm._s(_vm._f("dateFormat1")(_vm.datas.departureDate)) +
                        " - " +
                        _vm._s(
                          _vm._f("dateFormat1")(
                            _vm.datas.departureDate +
                              _vm.datas.planDay * 86400000
                          )
                        )
                    ),
                  ]),
                  _c("p", { staticClass: "planeDetailRp2" }, [
                    _vm._v(_vm._s(_vm.datas.useNumber) + " 名贵宾"),
                  ]),
                  _c("p", { staticClass: "planeDetailRp3" }, [
                    _vm._v(_vm._s(_vm.datas.planDay) + "天 "),
                    _c("span", { staticStyle: { margin: "0 0.1rem" } }, [
                      _vm._v("/"),
                    ]),
                    _vm._v(" "),
                    _vm.datas.planName
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.datas.countrySize) + "个国家"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", { staticStyle: { margin: "0 0.1rem" } }, [
                      _vm._v("/"),
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(_vm.datas.spotSize ? _vm.datas.spotSize : 0) +
                        "个景点"
                    ),
                  ]),
                ]),
              ]),
              _vm.datas.budgetDetailResult
                ? _c("div", {
                    staticClass: "line",
                    staticStyle: { "margin-bottom": "0.6rem" },
                    style: {
                      marginTop: `${
                        (0.47 / 0.67) *
                          _vm.fontSize(
                            parseInt(
                              _vm.datas.budgetDetailResult.saleTotalPrice /
                                _vm.datas.useNumber
                            ),
                            3.5,
                            0.67
                          ) +
                        "rem"
                      }`,
                    },
                  })
                : _c("div", {
                    staticClass: "line",
                    staticStyle: {
                      "margin-bottom": "0.6rem",
                      "margin-top": "0.47rem",
                    },
                  }),
            ])
          : _vm._e(),
        _c("div", { staticClass: "btnBox" }, [
          _vm._obtainPriceDisplayStatus().PERSONAL_INFORMATION ||
          _vm._obtainPriceDisplayStatus().COMPANY_INFORMATION ||
          _vm._h5groupDetailsStrategy().ITINERARY_MAP
            ? _c(
                "div",
                {
                  staticClass: "btn",
                  on: {
                    click: function ($event) {
                      return _vm.jumpPoint("方案概括")
                    },
                  },
                },
                [
                  _c("img", {
                    staticStyle: {
                      height: "0.55rem",
                      "margin-right": "0.1rem",
                    },
                    attrs: {
                      src: require("@/assets/png/plane/WechatIMG301.jpeg"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "text" }, [_vm._v(" 方案概括 ")]),
                  _vm._h5groupDetailsStrategy().ITINERARY_MAP ||
                  _vm._isDetailPrice().DETAIL_PRICE ||
                  _vm.programmeId > 0
                    ? _c("div", { staticClass: "line1" })
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm._h5groupDetailsStrategy().ITINERARY_MAP
            ? _c(
                "div",
                {
                  staticClass: "btn",
                  on: {
                    click: function ($event) {
                      return _vm.jumpPoint("行程地图")
                    },
                  },
                },
                [
                  _c("img", {
                    staticStyle: {
                      height: "0.55rem",
                      "margin-right": "0.1rem",
                    },
                    attrs: {
                      src: require("@/assets/png/plane/WechatIMG302.jpeg"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "text" }, [_vm._v(" 行程地图 ")]),
                  _vm._isDetailPrice().DETAIL_PRICE || _vm.programmeId > 0
                    ? _c("div", { staticClass: "line1" })
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm._isDetailPrice().DETAIL_PRICE
            ? _c(
                "div",
                {
                  staticClass: "btn",
                  on: {
                    click: function ($event) {
                      return _vm.jumpPoint("详细报价")
                    },
                  },
                },
                [
                  _c("img", {
                    staticStyle: {
                      height: "0.55rem",
                      "margin-right": "0.1rem",
                    },
                    attrs: {
                      src: require("@/assets/png/plane/WechatIMG303.jpeg"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "text" }, [_vm._v(" 详细报价 ")]),
                  _vm.programmeId > 0
                    ? _c("div", { staticClass: "line1" })
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm.programmeId > 0
            ? _c(
                "div",
                {
                  staticClass: "btn",
                  on: {
                    click: function ($event) {
                      return _vm.jumpPoint("价格说明")
                    },
                  },
                },
                [
                  _c("img", {
                    staticStyle: {
                      height: "0.55rem",
                      "margin-right": "0.1rem",
                    },
                    attrs: {
                      src: require("@/assets/png/plane/WechatIMG304.jpeg"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "text" }, [_vm._v(" 价格说明 ")]),
                ]
              )
            : _vm._e(),
        ]),
      ]),
      _vm._h5groupDetailsStrategy().F
        ? _c(
            "div",
            {
              staticClass: "line3Box",
              staticStyle: {
                "margin-bottom": "0.8rem",
                "margin-top": "0.6rem",
              },
            },
            [_vm._m(1)]
          )
        : _vm._e(),
      _vm._obtainPriceDisplayStatus().COMPANY_INFORMATION
        ? _c("div", { staticClass: "twoBox" }, [
            _c("img", {
              staticStyle: { width: "1rem", height: "1rem" },
              attrs: { src: _vm.userInfo.logs, alt: "" },
            }),
            _c("p", { staticClass: "p2" }, [
              _vm._v(_vm._s(_vm.userInfo.mechanismName)),
            ]),
            _c("p", { staticClass: "p3" }, [
              _vm._v(_vm._s(_vm.userInfo.slogan)),
            ]),
            _c("p", { staticClass: "p4" }, [
              _vm._v(_vm._s(_vm.userInfo.adressRegistration)),
            ]),
            _c("p", { staticClass: "p5" }, [
              _vm._v("设立 "),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.companyEstablishmentYear(_vm.userInfo.startTime))
                ),
              ]),
              _vm._v("年 覆盖"),
              _c("span", [_vm._v(_vm._s(_vm.userInfo.countrySize))]),
              _vm._v("国 跨越"),
              _c("span", [_vm._v(_vm._s(_vm.userInfo.citySize))]),
              _vm._v("城"),
            ]),
            _c("p", { staticClass: "p6" }, [_vm._v("10粉丝 / 5关注 / 28获赞")]),
            _c("p", { staticClass: "p7" }, [
              _vm._v("世界旅游城市联合会终身会员"),
            ]),
            _c(
              "div",
              { staticClass: "infoBoxB" },
              _vm._l(_vm.userInfo.uFileList, function (item) {
                return _c("img", {
                  key: item.id,
                  staticClass: "infoBoxBImgs",
                  attrs: { src: item.fileUrl, alt: "" },
                })
              }),
              0
            ),
          ])
        : _vm._e(),
      _vm._obtainPriceDisplayStatus().PERSONAL_INFORMATION
        ? _c("div", { staticClass: "threeBox" }, [
            _c("div", { staticClass: "infoBox" }, [
              _c("div", { staticClass: "infoBoxT" }, [
                _c("div", { staticClass: "infoBoxTInfo" }, [
                  _c("div", { staticClass: "infoBoxTInfoL" }, [
                    _c("img", {
                      staticClass: "infoBoxTimgs",
                      attrs: { src: _vm.userInfo.userInfoResult.imageUrl },
                    }),
                  ]),
                  _c("div", { staticClass: "infoBoxTInfoR" }, [
                    _c("p", { staticClass: "p1" }, [
                      _vm._v(_vm._s(_vm.userInfo.userInfoResult.userName)),
                    ]),
                    _vm.userInfo.userInfoResult.career
                      ? _c("div", { staticClass: "infoBoxTRTItle" }, [
                          _c("div", {
                            staticClass: "zhedian",
                            staticStyle: { left: "-0.3rem" },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.userInfo.userInfoResult.career) +
                              " "
                          ),
                          _c("div", {
                            staticClass: "zhedian",
                            staticStyle: { right: "-0.3rem" },
                          }),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "relationLineBox" }, [
                      _c("img", {
                        staticClass: "svg1",
                        attrs: {
                          src: require("@/assets/png/plane/WechatIMG322.png"),
                        },
                      }),
                      _c(
                        "a",
                        {
                          staticClass: "relationLineBoxP1",
                          attrs: {
                            href:
                              "tel:" + _vm.userInfo.userInfoResult.loginAccount,
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.userInfo.userInfoResult.loginAccount)
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "datumBox" },
                  _vm._l(_vm.datumList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _c("div", { staticClass: "datumBoxLineBox" }, [
                          _c("p", { staticClass: "datumBoxLineP1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.calculationPeriod(
                                  _vm.userInfo.userInfoResult,
                                  item
                                )
                              )
                            ),
                          ]),
                          _c("p", { staticClass: "datumBoxLineP2" }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                        ]),
                        index != _vm.datumList.length - 1
                          ? _c("div", { staticClass: "datumBoxLine" })
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "infoBoxC" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.userInfo.userInfoResult.customerMessage) +
                    " "
                ),
              ]),
              _c(
                "div",
                { staticClass: "infoBoxB" },
                _vm._l(_vm.userInfo.userInfoResult.uFileList, function (item) {
                  return _c("img", {
                    key: item.id,
                    staticClass: "infoBoxBImgs",
                    attrs: { src: item.fileUrl, alt: "" },
                  })
                }),
                0
              ),
            ]),
          ])
        : _vm._e(),
      _vm._obtainPriceDisplayStatus().PERSONAL_INFORMATION
        ? _c("div", { staticClass: "line3Box" }, [_vm._m(2)])
        : _vm._e(),
      _vm._h5groupDetailsStrategy().ITINERARY_MAP && _vm.datas.planMap
        ? _c("div", { staticClass: "fourBox" }, [
            _c(
              "p",
              { staticClass: "p1", staticStyle: { "margin-bottom": "0.1rem" } },
              [_vm._v("行程地图")]
            ),
            _c("p", { staticClass: "p2" }),
            _c("p", { staticClass: "p3" }, [
              _vm._v("THT MAP OF YOUR OWN JOURNEY"),
            ]),
            _c("img", { attrs: { src: _vm.datas.planMap, alt: "" } }),
          ])
        : _vm._e(),
      _vm._h5groupDetailsStrategy().ITINERARY_MAP && _vm.datas.planMap
        ? _c("div", { staticClass: "line3Box" }, [_vm._m(3)])
        : _vm._e(),
      _c(
        "div",
        {
          ref: "targetElement",
          staticClass: "fourBox",
          staticStyle: { padding: "0 0.4rem" },
        },
        [
          _c(
            "p",
            { staticClass: "p1", staticStyle: { "margin-bottom": "0.1rem" } },
            [_vm._v("节点行程")]
          ),
          _c("p", { staticClass: "p2" }),
          _c("p", { staticClass: "p3" }, [
            _vm._v("THT ITINERARY OF YOUR OWN JOURNEY"),
          ]),
          _c(
            "main",
            { staticStyle: { width: "100%" }, attrs: { id: "main" } },
            _vm._l(_vm.planData, function (day, dayIndex) {
              return _c(
                "div",
                { key: dayIndex, staticClass: "Plan-Content" },
                [
                  _c("div", { staticClass: "PC-One" }, [
                    _c("span", { staticClass: "day_icon PCO-Icon" }, [
                      _vm._v("D" + _vm._s(day.planDay)),
                    ]),
                    _c("div", { staticClass: "PCO-City-Date" }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm._f("dateFormat")(day.planDate))),
                      ]),
                      _c("img", {
                        staticClass: "Animation",
                        attrs: { src: require("@/assets/png/mapIcon.png") },
                        on: {
                          click: function ($event) {
                            return _vm.jumpMap(day)
                          },
                        },
                      }),
                      _c(
                        "span",
                        {
                          staticStyle: { flex: "3" },
                          on: {
                            click: function ($event) {
                              return _vm.openMapNavigation(day)
                            },
                          },
                        },
                        [_vm._v(_vm._s(day.cityStr))]
                      ),
                    ]),
                  ]),
                  _c("div"),
                  _vm._l(day.budgetList, function (budget, budgetIndex) {
                    return _c(
                      "div",
                      { key: budgetIndex, staticClass: "PCO-Content" },
                      [
                        budget.state == 11 &&
                        (budget.resourceNameCn || budget.remark)
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-direction": "column",
                                  padding: "10px",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.jumpMap(day)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(budget.resourceNameCn) +
                                        " " +
                                        _vm._s(budget.timeStr) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticStyle: { "font-size": "12px" } },
                                  [_vm._v(_vm._s(budget.remark))]
                                ),
                              ]
                            )
                          : _c("BudgetContent", {
                              attrs: {
                                detailFlag: false,
                                budget: budget,
                                day: day,
                                individual: false,
                              },
                            }),
                      ],
                      1
                    )
                  }),
                ],
                2
              )
            }),
            0
          ),
        ]
      ),
      _vm.show
        ? _c(
            "div",
            { staticClass: "fourBox" },
            [
              _c(
                "p",
                {
                  staticClass: "p1",
                  staticStyle: { "margin-bottom": "0.1rem" },
                },
                [_vm._v("行程描述")]
              ),
              _c("p", { staticClass: "p2" }),
              _c("p", { staticClass: "p3" }, [
                _vm._v("THT TRIP OF YOUR OWN DESCRIPTIPN"),
              ]),
              _c("Ueditor", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.show,
                    expression: "show",
                  },
                ],
                ref: "ueditor",
                staticClass: "myUeditor",
                attrs: {
                  readonly: true,
                  autoSyncData: false,
                  bar: _vm.bars,
                  enableContextMenu: false,
                },
                on: {
                  ueditorChange: function ($event) {
                    _vm.loading = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.tuiTeDate.length > 0
        ? _c("div", { staticClass: "line3Box" }, [_vm._m(4)])
        : _vm._e(),
      _vm.tuiTeDate.length > 0 &&
      _vm._h5groupDetailsStrategy().FEATURED_RECOMMENDATIONS
        ? _c(
            "div",
            { staticClass: "fourBox" },
            [
              _c(
                "p",
                {
                  staticClass: "p1",
                  staticStyle: { "margin-bottom": "0.1rem" },
                },
                [_vm._v("行程特色")]
              ),
              _c("p", { staticClass: "p2" }),
              _c("p", { staticClass: "p3" }, [
                _vm._v("THE ITINERARY OF YOUR OWN JOURNEY"),
              ]),
              _vm._l(_vm.tuiTeDate, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "fourBox spotBox" },
                  [
                    _c("p", { staticClass: "p4" }, [
                      _vm._v(_vm._s(item.resourceName)),
                    ]),
                    _c("div", { staticClass: "fenge" }, [
                      _c("div", { staticClass: "fengeLine" }),
                      _c("img", {
                        staticStyle: { width: "1rem" },
                        attrs: {
                          src: require("@/assets/png/fenge.jpeg"),
                          alt: "",
                        },
                      }),
                      _c("div", { staticClass: "fengeLine" }),
                    ]),
                    _vm._l(item.tweets, function (n, m) {
                      return _c(
                        "div",
                        { key: m, staticClass: "Img-Show" },
                        [
                          _c("p", { staticClass: "p5" }, [
                            _vm._v(" " + _vm._s(n.word) + " "),
                          ]),
                          _vm._l(n.picList, function (n1, m1) {
                            return _c("img", {
                              key: m1,
                              staticClass: "imgs3",
                              attrs: { src: n1, alt: "" },
                            })
                          }),
                        ],
                        2
                      )
                    }),
                  ],
                  2
                )
              }),
            ],
            2
          )
        : _vm._e(),
      JSON.stringify(_vm.resourceData) !== "{}"
        ? _c("div", [
            _vm._h5groupDetailsStrategy().FEATURED_RECOMMENDATIONS &&
            _vm.tuiTeDate.length > 0
              ? _c("div", { staticClass: "line3Box" }, [_vm._m(5)])
              : _vm._e(),
            _vm._h5groupDetailsStrategy().REFERENCE_RESTAURAN
              ? _c(
                  "div",
                  {
                    staticClass: "fourBox",
                    staticStyle: { "margin-bottom": "0.4rem" },
                  },
                  [
                    _vm._m(6),
                    _c(
                      "p",
                      {
                        staticClass: "p3",
                        staticStyle: { "margin-bottom": "0" },
                      },
                      [_vm._v("THE ITINERARY OF YOUR OWN JOURNEY")]
                    ),
                    _c("div", { staticClass: "fenge" }, [
                      _c("div", { staticClass: "fengeLine" }),
                      _c("img", {
                        staticStyle: { width: "1rem" },
                        attrs: {
                          src: require("@/assets/png/fenge.jpeg"),
                          alt: "",
                        },
                      }),
                      _c("div", { staticClass: "fengeLine" }),
                    ]),
                    false
                      ? _c(
                          "div",
                          [
                            _vm._m(7),
                            _vm._l(
                              _vm.resourceData.mealStandards,
                              function (item, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "featureDetail" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "featureDetailTitle" },
                                      [
                                        _vm._m(8, true),
                                        _c(
                                          "p",
                                          {
                                            staticClass: "featureDetailTitleP1",
                                          },
                                          [_vm._v(_vm._s(item.resourceName))]
                                        ),
                                      ]
                                    ),
                                    _c("img", {
                                      staticClass: "featureDetailImg",
                                      attrs: { src: item.imageUrl, alt: "" },
                                    }),
                                    _c(
                                      "p",
                                      { staticClass: "featureDetailText" },
                                      [_vm._v(" " + _vm._s(item.word))]
                                    ),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
            _vm.resourceData.meals.length > 0 &&
            _vm._h5groupDetailsStrategy().REFERENCE_RESTAURANT
              ? _c(
                  "div",
                  {
                    staticClass: "fourBox",
                    staticStyle: { "margin-bottom": "0.4rem" },
                  },
                  [
                    _vm._m(9),
                    _c(
                      "div",
                      { staticClass: "canteenDetail" },
                      _vm._l(_vm.resourceData.meals, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "canteenDetailBox" },
                          [
                            _c("div", { staticClass: "canteenDetailBoxL" }, [
                              _c("p", { staticClass: "canteenDetailBoxLp1" }, [
                                _vm._v(
                                  _vm._s(
                                    item.cityNameCn
                                      ? item.cityNameCn
                                      : item.cityName
                                  )
                                ),
                              ]),
                              _c("p", { staticClass: "canteenDetailBoxLp2" }, [
                                _vm._v(
                                  _vm._s(
                                    item.nameCn ? item.nameCn : _vm.itme.nameEn
                                  )
                                ),
                              ]),
                              item.otherResourceData &&
                              JSON.stringify(item.otherResourceData) !== "{}"
                                ? _c(
                                    "p",
                                    { staticClass: "canteenDetailBoxLp3" },
                                    [
                                      _vm._v(
                                        _vm._s(item.otherResourceData.cuisine)
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              item.score
                                ? _c(
                                    "p",
                                    { staticClass: "canteenDetailBoxLp4" },
                                    [
                                      _vm._v(" 口味分: "),
                                      _c(
                                        "span",
                                        { staticClass: "canteenDetailBoxLp6" },
                                        [_vm._v(_vm._s(item.score))]
                                      ),
                                      _vm._v(" 星 "),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "p",
                                { staticClass: "canteenDetailBoxLp5" },
                                [
                                  _c("svg-icon", {
                                    staticStyle: {
                                      height: "0.16rem",
                                      width: "0.18rem",
                                    },
                                    attrs: { iconClass: "didian" },
                                  }),
                                  _vm._v(
                                    _vm._s(
                                      item.address
                                        ? item.address
                                        : item.addressCn
                                    )
                                  ),
                                ],
                                1
                              ),
                              _c("p", { staticClass: "canteenDetailBoxLp3" }, [
                                _vm._v(_vm._s(item.introduction)),
                              ]),
                              _c(
                                "div",
                                { staticClass: "canteenDetailBoxLine" },
                                [
                                  _c("div", {
                                    staticClass: "canteenDetailBoxLineyuan",
                                  }),
                                  index != _vm.resourceData.meals.length - 1
                                    ? _c("div", {
                                        staticClass: "canteenDetailBoxLines",
                                      })
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                            item.imageUrl
                              ? _c(
                                  "div",
                                  { staticClass: "canteenDetailBoxR" },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: item.imageUrl
                                          ? item.imageUrl
                                          : require("../../assets/png/zanwutupian.jpg"),
                                        alt: "",
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                )
              : _vm._e(),
            _vm.resourceData.hasMeal &&
            _vm._h5groupDetailsStrategy().REFERENCE_RESTAURANT
              ? _c("div", { staticClass: "line3Box" }, [_vm._m(10)])
              : _vm._e(),
            _vm.resourceData.hasMeal &&
            _vm._h5groupDetailsStrategy().REFERENCE_RESTAURANT
              ? _c(
                  "div",
                  {
                    staticClass: "fourBox",
                    staticStyle: { "margin-bottom": "0.4rem" },
                  },
                  [
                    _vm._m(11),
                    _c(
                      "div",
                      { staticClass: "meetImgBox" },
                      _vm._l(_vm.meetNamelist, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "meetImgs" },
                          [
                            _c("img", {
                              attrs: {
                                src: require(`@/assets/png/plane/WechatIMG${
                                  index + 1
                                }.jpeg`),
                                alt: "",
                              },
                            }),
                            _c("p", { staticClass: "meetImgsP1" }, [
                              _vm._v(_vm._s(item)),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                )
              : _vm._e(),
            _vm.resourceData.hasMeal &&
            _vm._h5groupDetailsStrategy().REFERENCE_RESTAURANT
              ? _c("div", { staticClass: "line3Box" }, [_vm._m(12)])
              : _vm._e(),
            _vm.resourceData.hotels.length > 0 &&
            _vm._h5groupDetailsStrategy().REFERENCE_HOTEL
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "fourBox",
                      staticStyle: { "margin-bottom": "0.2rem" },
                    },
                    [
                      _vm._m(13),
                      _c(
                        "p",
                        {
                          staticClass: "p3",
                          staticStyle: { "margin-bottom": "0" },
                        },
                        [_vm._v("THE HOTELS OF YOUR OWN JOURNEY")]
                      ),
                      _c("div", { staticClass: "fenge" }, [
                        _c("div", { staticClass: "fengeLine" }),
                        _c("img", {
                          staticStyle: { width: "1rem" },
                          attrs: {
                            src: require("@/assets/png/fenge.jpeg"),
                            alt: "",
                          },
                        }),
                        _c("div", { staticClass: "fengeLine" }),
                      ]),
                      _c("div", { staticClass: "p6" }, [
                        _vm._v(" 酒店以最终安排为准 "),
                      ]),
                      _vm.resourceData.hotelDates.length > 0 ||
                      _vm.resourceData.hotelStandards.length > 0
                        ? _c(
                            "div",
                            { staticClass: "meetInfo" },
                            [
                              _vm._l(
                                _vm.resourceData.hotelDates,
                                function (item, index) {
                                  return _c(
                                    "p",
                                    { key: index, staticClass: "meetInfoP1" },
                                    [_vm._v(_vm._s(item))]
                                  )
                                }
                              ),
                              _vm._l(
                                _vm.resourceData.hotelStandards,
                                function (item, index) {
                                  return _c(
                                    "p",
                                    { key: item, staticClass: "meetInfoP2" },
                                    [_vm._v(_vm._s(item))]
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "hotelDetail" },
                    _vm._l(_vm.resourceData.hotels, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "hotelDetailBox" },
                        [
                          _c("div", { staticClass: "imgBox" }, [
                            _c("img", {
                              attrs: { src: item.imageUrl, alt: "" },
                            }),
                            item.star
                              ? _c(
                                  "div",
                                  { staticClass: "star" },
                                  _vm._l(item.star, function (n, m) {
                                    return _c("span", { key: m }, [_vm._v("*")])
                                  }),
                                  0
                                )
                              : _vm._e(),
                          ]),
                          _c(
                            "p",
                            { staticClass: "hotelDetailBoxP1" },
                            [
                              _c("svg-icon", {
                                staticStyle: {
                                  height: "0.16rem",
                                  width: "0.18rem",
                                },
                                attrs: { iconClass: "didian" },
                              }),
                              _c("span", [
                                _vm._v(" " + _vm._s(item.addressCn)),
                              ]),
                            ],
                            1
                          ),
                          _c("p", { staticClass: "hotelDetailBoxP2" }, [
                            _vm._v(_vm._s(item.nameCn)),
                          ]),
                          _c("p", { staticClass: "hotelDetailBoxP3" }, [
                            _vm._v(
                              "酒店综合评分:" +
                                _vm._s(item.score ? item.score : "暂无")
                            ),
                          ]),
                          _c("p", { staticClass: "hotelDetailBoxP4" }, [
                            _vm._v(_vm._s(item.introduction)),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
            _vm.resourceData.hotels.length > 0 &&
            _vm._h5groupDetailsStrategy().REFERENCE_HOTEL
              ? _c("div", { staticClass: "line3Box" }, [_vm._m(14)])
              : _vm._e(),
            _vm.resourceData.bigTraffics.length > 0
              ? _c(
                  "div",
                  {
                    staticClass: "fourBox",
                    staticStyle: { "margin-bottom": "0.4rem" },
                  },
                  [
                    _vm._m(15),
                    _c(
                      "p",
                      {
                        staticClass: "p3",
                        staticStyle: { "margin-bottom": "0" },
                      },
                      [_vm._v("THE TRANSPORTATION OF YOUR OWN JOURNEY")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "fenge",
                        staticStyle: { "margin-bottom": "1.5rem" },
                      },
                      [
                        _c("div", { staticClass: "fengeLine" }),
                        _c("img", {
                          staticStyle: { width: "1rem" },
                          attrs: {
                            src: require("@/assets/png/fenge.jpeg"),
                            alt: "",
                          },
                        }),
                        _c("div", { staticClass: "fengeLine" }),
                      ]
                    ),
                    _c("div", { staticClass: "planeDetail" }, [
                      _c(
                        "div",
                        {
                          staticClass: "planeDetailBox",
                          staticStyle: { "padding-top": "0.9rem" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "iconBox",
                              staticStyle: { height: "1.7rem" },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/png/plane/aircraft.jpeg"),
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                          _c("p", [_vm._v("1.CA931 北京-法兰 2020-0630")]),
                          _c("p", [_vm._v("2.CA931 北京-法兰 2020-0630")]),
                          _c("p", [_vm._v("3.CA931 北京-法兰 2020-0630")]),
                          _c("p", [_vm._v("4.CA931 北京-法兰 2020-0630")]),
                        ]
                      ),
                      _c("div", { staticClass: "planeDetailBox" }, [
                        _c("div", { staticClass: "iconBox" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/png/plane/train.jpeg"),
                              alt: "",
                            },
                          }),
                        ]),
                        _c("p", [_vm._v("1.CA931 北京-法兰 2020-0630")]),
                        _c("p", [_vm._v("2.CA931 北京-法兰 2020-0630")]),
                        _c("p", [_vm._v("3.CA931 北京-法兰 2020-0630")]),
                        _c("p", [_vm._v("4.CA931 北京-法兰 2020-0630")]),
                      ]),
                      _c("div", { staticClass: "planeDetailBox" }, [
                        _c("div", { staticClass: "iconBox" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/png/plane/bus.jpeg"),
                              alt: "",
                            },
                          }),
                        ]),
                        _c("p", [_vm._v("2023.11.15")]),
                        _c("p", [_vm._v("2023.11.15")]),
                        _c("p", [_vm._v("2023.11.15")]),
                        _c("p", [_vm._v("2023.11.15")]),
                      ]),
                      _c("div", { staticClass: "planeDetailBox" }, [
                        _c("div", { staticClass: "iconBox" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/png/plane/steamer.jpeg"),
                              alt: "",
                            },
                          }),
                        ]),
                        _c("p", [_vm._v("1.CA931 北京-法兰 2020-0630")]),
                        _c("p", [_vm._v("2.CA931 北京-法兰 2020-0630")]),
                        _c("p", [_vm._v("3.CA931 北京-法兰 2020-0630")]),
                        _c("p", [_vm._v("4.CA931 北京-法兰 2020-0630")]),
                      ]),
                      _c("div", { staticClass: "planeDetailBox" }, [
                        _c("div", { staticClass: "iconBox" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/png/plane/car.jpeg"),
                              alt: "",
                            },
                          }),
                        ]),
                        _c("p", [_vm._v("2023.11.15")]),
                        _c("p", [_vm._v("2023.11.15")]),
                        _c("p", [_vm._v("2023.11.15")]),
                        _c("p", [_vm._v("2023.11.15")]),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _c("router-view"),
      _c("map-navigation", {
        ref: "mapNavigation",
        attrs: { pointMap: _vm.pointMap },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "planeDetailL" }, [
      _c("div", { staticClass: "yuan" }, [
        _c("div", { staticClass: "fx" }, [_vm._v(" f ")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "line3" }, [
      _c("div", { staticClass: "yuan1", staticStyle: { left: "-0.06rem" } }),
      _c("div", { staticClass: "yuan1", staticStyle: { right: "-0.06rem" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "line3", staticStyle: { margin: "0.5rem 0" } },
      [
        _c("div", { staticClass: "yuan1", staticStyle: { left: "-0.06rem" } }),
        _c("div", { staticClass: "yuan1", staticStyle: { right: "-0.06rem" } }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "line3", staticStyle: { margin: "0.5rem 0" } },
      [
        _c("div", { staticClass: "yuan1", staticStyle: { left: "-0.06rem" } }),
        _c("div", { staticClass: "yuan1", staticStyle: { right: "-0.06rem" } }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "line3",
        staticStyle: { margin: "0.5rem 0", "margin-top": "0" },
      },
      [
        _c("div", { staticClass: "yuan1", staticStyle: { left: "-0.06rem" } }),
        _c("div", { staticClass: "yuan1", staticStyle: { right: "-0.06rem" } }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "line3", staticStyle: { margin: "0.5rem 0" } },
      [
        _c("div", { staticClass: "yuan1", staticStyle: { left: "-0.06rem" } }),
        _c("div", { staticClass: "yuan1", staticStyle: { right: "-0.06rem" } }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { position: "relative" } }, [
      _c(
        "p",
        { staticClass: "p1 fn", staticStyle: { "margin-bottom": "0.05rem" } },
        [_vm._v("餐饮安排")]
      ),
      _c("div", { staticClass: "fourBoxLine1" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "featureTitle" }, [
      _c("div", { staticClass: "featureTitleBox" }, [
        _c("div", { staticClass: "featureTitleBoxLine" }),
        _vm._v(" 特色餐 "),
        _c("div", { staticClass: "featureTitleBoxLine" }),
      ]),
      _c("p", { staticClass: "featureTitleP1" }, [
        _vm._v("特色餐以最终安排为准"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "featureDetailTitleBox" }, [
      _c("div", {
        staticClass: "featureDetailTitleBoxChilc",
        staticStyle: { top: "0", left: "0" },
      }),
      _c("div", {
        staticClass: "featureDetailTitleBoxChilc",
        staticStyle: { top: "0", right: "0" },
      }),
      _c("div", {
        staticClass: "featureDetailTitleBoxChilc",
        staticStyle: { bottom: "0", right: "0" },
      }),
      _c("div", {
        staticClass: "featureDetailTitleBoxChilc",
        staticStyle: { bottom: "0", left: "0" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "featureTitle",
        staticStyle: { "margin-bottom": "0.4rem" },
      },
      [
        _c("div", { staticClass: "featureTitleBox" }, [
          _c("div", { staticClass: "featureTitleBoxLine" }),
          _vm._v(" 参考餐厅 "),
          _c("div", { staticClass: "featureTitleBoxLine" }),
        ]),
        _c("p", { staticClass: "featureTitleP1" }, [
          _vm._v("餐厅以最终安排为准"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "line3", staticStyle: { "margin-bottom": "0.5rem" } },
      [
        _c("div", { staticClass: "yuan1", staticStyle: { left: "-0.06rem" } }),
        _c("div", { staticClass: "yuan1", staticStyle: { right: "-0.06rem" } }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "featureTitle",
        staticStyle: { "margin-bottom": "0.4rem" },
      },
      [
        _c("div", { staticClass: "featureTitleBox" }, [
          _c("div", {
            staticClass: "featureTitleBoxLine",
            staticStyle: { width: "2.6rem" },
          }),
          _c("p", { staticStyle: { margin: "0.05rem 0" } }, [
            _vm._v("参考菜式(中餐)"),
          ]),
          _c("div", {
            staticClass: "featureTitleBoxLine",
            staticStyle: { width: "2.6rem" },
          }),
        ]),
        _c("p", { staticClass: "featureTitleP1" }, [
          _vm._v("菜式以最终安排为准"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "line3", staticStyle: { margin: "0.3rem 0 0.5rem 0" } },
      [
        _c("div", { staticClass: "yuan1", staticStyle: { left: "-0.06rem" } }),
        _c("div", { staticClass: "yuan1", staticStyle: { right: "-0.06rem" } }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { position: "relative" } }, [
      _c(
        "p",
        { staticClass: "p1 fn", staticStyle: { "margin-bottom": "0.05rem" } },
        [_vm._v("参考酒店")]
      ),
      _c("div", { staticClass: "fourBoxLine1" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "line3", staticStyle: { margin: "0.3rem 0 0.5rem 0" } },
      [
        _c("div", { staticClass: "yuan1", staticStyle: { left: "-0.06rem" } }),
        _c("div", { staticClass: "yuan1", staticStyle: { right: "-0.06rem" } }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { position: "relative" } }, [
      _c(
        "p",
        { staticClass: "p1", staticStyle: { "margin-bottom": "0.1rem" } },
        [_vm._v("交通安排")]
      ),
      _c("div", { staticClass: "fourBoxLine1" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }